<template>
  <div>
    <vs-popup class="sm:popup-w-70" :title="'Query untuk: ' + paramName " :active="isActive" v-on:update:active="toggleActive">
      <div class="h-screen-80 flex flex-col">
        <div class="flex-grow">
          <div class="w-full h-full d-theme-border-grey-light border border-solid">
            <div class="h-full">
              <AceEditor v-if="isActive" ref="aceEditor" v-model="data.query" @init="onInitAceEditor" lang="mysql" theme="dracula" :options="aceEditorOptions" width="100%"></AceEditor>
            </div>
          </div>
        </div>
        <div class="flex-none bg-danger" v-if="listOfParameterNameCannotResolved.length > 0">
          <p class="text-white px-2 py-1 text-sm">
            Cannot use parameter
            <span class="font-bold" v-for="(paramName, index) in listOfParameterNameCannotResolved" :key="index">
              {{ paramName }}{{ index === listOfParameterNameCannotResolved.length - 1 ? '' : ',' }}
            </span>
            because that parameter cannot resolved by system.
          </p>
        </div>
        <div class="flex-none bg-primary" v-if="listOfParameterNameCanResolved.length > 0">
          <p class="text-white px-2 py-1 text-sm">
            Parameter
            <span class="font-bold" v-for="(paramName, index) in listOfParameterNameCanResolved" :key="index">
              {{ paramName }}{{ index === listOfParameterNameCanResolved.length - 1 ? '' : ',' }}
            </span>
            is automatically resolved by system.
          </p>
        </div>
        <div class="flex-none mt-5">
          <div class="h-full w-full flex justify-between items-center">
            <div class="flex flex-wrap items-center justify-start pl-1">
              <div v-if="isAnyUnsavedChanges">
                <p class="text-sm text-primary font-normal">Perubahan Terdeteksi <span class="mx-1">|</span> <span class="underline cursor-pointer font-semibold" @click="resetQuery">Reset Query</span></p>
              </div>
            </div>
            <div class="flex items-center space-x-2">
              <p class="mr-4 text-primary cursor-pointer select-none" @click="beautify">Beautify</p>
              <vs-button type="border" icon-pack="feather" icon="icon-help-circle" class="px-2" @click="modalInjectedParams.active = true">Injected Parameters</vs-button>
              <vs-button type="border" @click="toggleActive(false)">Batal</vs-button>
              <vs-button @click="emitSaved" :disabled="listOfParameterNameCannotResolved.length > 0">Save</vs-button>
            </div>
          </div>
        </div>
      </div>

    <!--modals-->
    <ModalListInjectedParameters :isActive.sync="modalInjectedParams.active"/>

    </vs-popup>
  </div>
</template>

<script>
import { format } from 'sql-formatter-plus'
import _ from 'lodash'
import { getSqlQueryParameters } from '@/utilities/common/global-methods'
import reportMixin from '@/utilities/mixins/report-mixin'
import ModalListInjectedParameters from '@/views/pages/reports/add/ModalListInjectedParameters.vue'

export default {
  name: 'LinkedDataQueryEditor',
  props: ['isActive', 'paramName', 'query'],
  mixins: [reportMixin],
  components: {
    ModalListInjectedParameters,
    AceEditor: require('vue2-ace-editor')
  },
  watch: {
    isActive (active) {
      if (active) {
        this.initData.query = this.query
        this.data.query = this.query
      }
    }
  },
  computed: {
    isAnyUnsavedChanges () {
      return !_.isEqual(_.cloneDeep(this.data.query), _.cloneDeep(this.initData.query))
    },
    parameters () {
      if (!this.data.query) return []
      return getSqlQueryParameters(this.data.query)
    },
    listOfParameterNameCanResolved () {
      return this.filterParametersThatAutomaticInjected(this.parameters)
    },
    listOfParameterNameCannotResolved () {
      return this.filterParametersThatNotAutomaticInjected(this.parameters)
    }
  },
  data () {
    return {
      initData: {
        query: ''
      },
      data: {
        query: ''
      },
      modalInjectedParams: {
        active: false
      },
      aceEditorOptions: {
        showPrintMargin: false,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        highlightActiveLine: true,
        highlightSelectedWord: true
      }
    }
  },
  methods: {
    onInitAceEditor (editor) {
      require('brace/ext/language_tools')
      require('brace/ext/searchbox')
      require('brace/mode/mysql')
      require('brace/theme/dracula')
      require('brace/snippets/mysql')
      editor.focus()
    },

    toggleActive (active) {
      if (!active && this.isAnyUnsavedChanges) {
        return this.confirmClose()
      }
      this.emitModalIsActive(active)
    },

    confirmClose () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Terdapat perubahan yang belum disimpan, yakin ingin close?',
        acceptText: 'Ya',
        cancelText: 'Batal',
        accept: () => {
          this.emitModalIsActive(false)
          this.resetQuery()
        }
      })
    },

    resetQuery () {
      this.data.query = this.initData.query
    },

    beautify () {
      this.data.query = format(this.data.query, {
        language: 'sql',
        uppercase: true
      })
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    },

    emitSaved () {
      this.$emit('saved', this.data.query)
      this.emitModalIsActive(false)
    }
  }
}
</script>
