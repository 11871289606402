<template>
  <div>
    <vs-popup class="popup-content-no-padding sm:popup-w-70" title="List of Injected Parameters" :active="isActive" v-on:update:active="emitModalIsActive">
      <div class="vx-col w-full p-2">
        <div class="con-vs-alert con-vs-alert-primary con-icon">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            These parameters will auto injected and auto resolved by system. You can use these parameters if needed.
          </div>
        </div>
      </div>

      <div class="w-full h-screen-60 flex flex-col p-2 overflow-hidden">
        <div class="flex-1 overflow-auto border border-solid d-theme-border-grey-light">
          <table class="table-auto table-sticky border-collapse w-full">
            <thead>
              <tr class="d-theme-dark-bg">
                <th class="border border-solid d-theme-border-grey-light p-2 text-left whitespace-no-wrap">Name</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-left whitespace-no-wrap">Alias</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-left whitespace-no-wrap">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(param, index) in items" :key="index" class="hover-bg-primary-transparent-10">
                <td class="text-sm p-2 border border-solid d-theme-border-grey-light text-left text-success font-bold">{{ param.name }}</td>
                <td class="text-sm p-2 border border-solid d-theme-border-grey-light text-left">{{ param.alias }}</td>
                <td class="text-sm p-2 border border-solid d-theme-border-grey-light text-left">{{ param.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import reportMixin from '@/utilities/mixins/report-mixin'

export default {
  name: 'ModalListInjectedParameters',
  props: ['isActive'],
  mixins: [reportMixin],
  computed: {
    items () {
      return this.getInjectedParameters()
    }
  },
  methods: {
    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>

<style scoped>
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
